import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/ryan-faria-logo.svg"
import Button from "./button"

const Header = ({ logoWidth }) => (
  <header>
    <div className="mx-auto py-10 flex justify-between px-4"  style={{maxWidth: 960}}>
      <Logo className={logoWidth}/>    
      <Button btnClasses="bg-yellow-500 py-2 px-4 rounded-full font-bold text-sm md:text-base leading-6" btnText="Get in touch"/>  
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
